import { createContext, useContext, useEffect, useRef, useState } from "react"
import toast, { Toaster } from "react-hot-toast"
import Button from "components/Button"
import Modal from "components/Modal"

// Create context
const notificationContext = createContext()

// Create hook
export const useNotifications = () => useContext(notificationContext)

/**
 * Create context provider
 */
export function ProvideNotifications({ children }) {
	const notifications = useNotificationsProvider()
	return (
		<notificationContext.Provider value={notifications}>
			{children}
			<Toaster
				toastOptions={{
					className: "text-sm",
				}}
			/>
		</notificationContext.Provider>
	)
}

/**
 * Confirmation component
 */
export function Confirmation({ isDestructive }) {
	// Local state
	const { confirmation, hideConfirm, awaitingConfirmationRef } =
		useNotifications()

	/**
	 * On accept
	 */
	const onAccept = () => {
		awaitingConfirmationRef?.current.resolve(true)
		hideConfirm()
	}

	/**
	 *
	 */
	const onCancel = () => {
		awaitingConfirmationRef?.current.resolve(false)
		hideConfirm()
	}

	/**
	 * Render confirmation component if we have something to show
	 */
	return (
		<Modal
			title={confirmation?.title}
			visible={confirmation?.visible}
			onClose={onCancel}
			slim
		>
			{confirmation?.content && (
				<div className="text-gray-600 text-center mb-4">
					{confirmation.content}
				</div>
			)}
			<div className="flex space-x-4">
				<div className="flex-1">
					<Button
						color={isDestructive ? "blue" : "red"}
						onClick={onCancel}
						block
					>
						Cancel
					</Button>
				</div>
				<div className="flex-1">
					<Button
						color={isDestructive ? "red" : "green"}
						onClick={onAccept}
						block
					>
						Confirm
					</Button>
				</div>
			</div>
		</Modal>
	)
}

/**
 * Set provider logic
 */
function useNotificationsProvider() {
	// Local state
	const [confirmation, setConfirmation] = useState({
		visible: false,
	})

	// Confirmation ref
	const awaitingConfirmationRef = useRef()

	/**
	 * Show success
	 */
	const success = (text) => toast.success(text)

	/**
	 * Show error
	 */
	const error = (text) => toast.error(text)

	/**
	 * Show confirm
	 * This will either return true or false (async) depending user action
	 */
	const showConfirm = async (content, title) => {
		return new Promise((resolve, reject) => {
			// Show confirmation
			setConfirmation({
				title,
				content,
				visible: true,
			})

			// Detect success or fail on modal and pass back resolve/reject
			awaitingConfirmationRef.current = { resolve, reject }
		})
	}

	/**
	 * Clear confirmation
	 */
	const hideConfirm = () => {
		setConfirmation({
			...confirmation,
			visible: false,
		})
	}

	/**
	 * Return data / methods
	 */
	return {
		success,
		error,
		showConfirm,
		hideConfirm,
		confirmation,
		awaitingConfirmationRef,
	}
}

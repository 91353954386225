import cookie from 'cookie'
import * as Sentry from "@sentry/nextjs";

/**
 * Generic request 
 */
const makeRequest = async (endpoint, method, headers = {}, body) => {

  try {


    // Create the request 
    const request = await fetch(`${process.env.NEXT_PUBLIC_API_URL}${endpoint}`, {
      method: method,
      credentials: 'include',
      headers: {
        ...headers,
        'Accept': 'application/json',
        'X-XSRF-TOKEN': cookie.parse(document.cookie)['XSRF-TOKEN'] || false
      },
      body: body
    })

    // Store the response if successful 
    const data = request.status !== 204 ? await request.json() : null

    // Return data 
    return {
      response: request,
      data
    }

  } catch (e) {

    Sentry.captureException(e);
    console.log('Request error: ', e)

    return {
      response: {
        ok: false
      },
      data: {
        errors: null
      },
    }

  }

}

/**
 * Make a PUT request to the server 
 */
const usePutRequest = async (endpoint, data) => {
  return makeRequest(endpoint, "PUT", {
    'Content-Type': 'application/json',
  }, JSON.stringify(data))
}

/**
 * Make a POST request to the server 
 */
const usePostRequest = async (endpoint, data) => {
  return makeRequest(endpoint, "POST", {
    'Content-Type': 'application/json'
  }, JSON.stringify(data))
}

/**
 * Make a POST request to the server with form data 
 */
const useFormRequest = async (endpoint, data) => makeRequest(endpoint, "POST", null, data)

/**
 * Make a GET request to the server
 */
const useGetRequest = async (endpoint) => makeRequest(endpoint, "GET", null, null)

/**
 * Make a DELETE request to the server
 */
const useDeleteRequest = async (endpoint) => makeRequest(endpoint, "DELETE", null, null, true)

export {
  usePostRequest,
  useFormRequest,
  usePutRequest,
  useGetRequest,
  useDeleteRequest
}
import useSWR from "swr"
import { usePostRequest, usePutRequest } from "hooks/requests"
import { createContext, useContext, useEffect, useState } from "react"
import { useAuth } from "../hooks/auth"
import { useNotifications } from "./notifications"
import { useRouter } from "next/router"
import { useRecoilState } from "recoil"

// Create display context 
const applicationContext = createContext()

// Create display hook 
export const useApplication = () => useContext(applicationContext)

/**
 * Create context provider  
 */
export function ProvideApplication({ children }) {
  const display = useApplicationProvider()
  return <applicationContext.Provider value={display}>{children}</applicationContext.Provider>
}

/**
 * Set display provider logic 
 */
function useApplicationProvider() {

  // Access router
  const router = useRouter()

  // Access notifications 
  const notifications = useNotifications()

  // Access user 
  const { user } = useAuth()

  // Local state
  const [estate, setEstate] = useState(null)
  const [ready, setReady] = useState(false)
  const [guest, setGuest] = useState(null)

  // On load 
  useEffect(() => {

    // Determine guest 
    setGuest(localStorage.getItem('guest'))

  }, [])

  // Get estate on mount 
  const { data: estates, error: estatesError, mutate: mutateEstates } = useSWR(() => user ? `/api/estates` : null, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    onSuccess(estates) {

      // If we have data 
      if (estates && estates.length > 0) {

        // Check for selected estate in storage 
        const cachedEstate = JSON.parse(localStorage.getItem('currentEstate'))

        // Default to first 
        let activeEstate = estates[0]

        // Find a match to selected estate 
        if (cachedEstate) {
          const matchedEstates = estates.filter(estate => estate.id === cachedEstate.id)
          if (matchedEstates.length > 0) {
            activeEstate = matchedEstates[0]
          }
        }

        // Set estate 
        localStorage.setItem('currentEstate', JSON.stringify(activeEstate))
        setEstate(activeEstate)

      } else {

        // Clear storage 
        localStorage.removeItem('currentEstate')
        setEstate(null)

      }

      // Set ready state 
      setReady(true)

    },
  })

  /**
   * Select estate
   */
  const selectEstate = (estate, redirect) => {
    if (!estate) return false
    localStorage.setItem('currentEstate', JSON.stringify(estate))

    // Redirect to specified location 
    if (redirect) window.location.href = redirect
    else setEstate(estate)
  }

  /**
   * Update estate 
   */
  const updateEstate = async (updatedProperties) => {
    if (!updatedProperties) return false

    try {

      // Send eequest 
      const { response, data } = await usePutRequest(`/api/estates/${estate.id}`, {
        ...estate,
        ...updatedProperties
      })

      // Check for error 
      if (!response.ok) throw new Error('Could not update the estate')

      // Mutate
      mutateEstates()

      // Return data 
      return data

    } catch (e) {

      console.log(e)
      notifications.error(e.message)
      return false

    }
  }

  /**
   * Create estate 
   */
  const createEstate = async (properties) => {
    if (!properties) return false

    try {

      // Send eequest 
      const { response, data } = await usePostRequest(`/api/estates`, properties)

      // Check for error 
      if (!response.ok) throw new Error('Could not create the estate')

      // Mutate
      mutateEstates()

      // Return data 
      return data

    } catch (e) {

      console.log(e)
      notifications.error(e.message)
      return false

    }
  }

  /**
   * Create estate task 
   */
  const createTask = async (properties) => {
    if (!properties) return false

    try {

      // Send eequest 
      const { response, data } = await usePostRequest(`/api/estates/${estate.id}/tasks`, {
        ...properties
      })

      // Check for error 
      if (!response.ok) throw new Error('Could not create the task')

      // Return data 
      return data

    } catch (e) {

      console.log(e)
      notifications.error(e.message)
      return false

    }
  }

  /**
   * Attach existing tasks to estate 
   */
  const attachTasks = async (tasks) => {
    if (!tasks) return false

    try {

      // Update server and get live task 
      const { response, data } = await usePostRequest(`/api/estates/${estate.id}/tasks/attach`, { tasks })

      // Check for error 
      if (!response.ok) throw new Error('Could not attach the tasks')

      // Return data 
      return data

    } catch (e) {

      console.log(e)
      notifications.error('Error updating tasks')
      return false

    }

  }

  return {
    ready,
    estate,
    estates,
    setEstate,
    createTask,
    updateEstate,
    mutateEstates,
    attachTasks,
    createEstate,
    selectEstate
  }

}
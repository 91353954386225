import 'styles/index.css'

import Head from 'next/head'
import { useRouter } from 'next/router'
import { useState, useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import { SWRConfig } from 'swr'
import { useGetRequest } from 'hooks/requests'
import { ProvideNotifications } from 'providers/notifications'
import { ProvideApplication } from 'providers/application'
import * as ga from '../lib/ga'

// Default fetcher 
const fetcher = async endpoint => {

   // Build request
   const { response, data } = await useGetRequest(`${endpoint}`)

   // Add more info to default error response
   if (!response.ok) {
      const error = new Error('An error occurred while fetching the data.')
      error.info = await response.json()
      error.status = response.status
      throw error
   }

   // Return JSON 
   return data
}

export default function MyApp({ Component, pageProps }) {

   // Local state
   const [showBeta, setShowBeta] = useState(false)

   // Access router 
   const router = useRouter()

   // On router events, trigger GA 
   useEffect(() => {
      const handleRouteChange = (url) => {
         ga.pageview(url)
      }
      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange)

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
         router.events.off('routeChangeComplete', handleRouteChange)
      }
   }, [router.events])

   // On load, check for beta notice setting
   useEffect(() => {
      if (!localStorage.getItem('hideBetaNotice')) setShowBeta(true)
   }, [])

   // Close beta notice
   const closeBetaNotice = () => {
      setShowBeta(false)
      localStorage.setItem('hideBetaNotice', true)
   }

   return (
      <>
         <Head>

            {/* Core title */}
            <title>Yonderlife - What to do when somebody dies</title>
            
         </Head>
         <SWRConfig value={{ fetcher: fetcher }}>
            <RecoilRoot>
               <ProvideNotifications>
                  <ProvideApplication>
                     <Component {...pageProps} />
                  </ProvideApplication>
               </ProvideNotifications>
            </RecoilRoot>
         </SWRConfig>

         {/* Beta notice */}
         {showBeta && (
            <div className='fixed bottom-0 right-0 p-4 z-50'>
               <div className='bg-black text-white text-sm rounded-md shadow p-4'>
                  <div className='flex space-x-4 items-center'>
                     <div>
                        We're currently in beta
                     </div>
                     <div>
                        <button type='button' className='underline' onClick={closeBetaNotice}>
                           Okay
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         )}

      </>
   )
}
import useSWR from 'swr'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useGetRequest, usePostRequest } from 'hooks/requests'

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {

  // Access router
  const router = useRouter()
  const { token } = router.query

  // Local state 
  const [guest, setGuest] = useState()
  const [processing, setProcessing] = useState(false)

  /**
   * On load
   */
  useEffect(() => {

    // Determine guest 
    setGuest(localStorage.getItem('guest'))

  }, [])

  /**
   * On guest change
   */
  useEffect(() => {

    // Save to storage
    if (guest && (localStorage.getItem('guest') !== guest)) {
      localStorage.setItem('guest', guest)
    }

  }, [guest])

  /**
   * Check for authenticated user 
   */
  const { data: user, error, mutate: revalidate } = useSWR(!processing ? '/api/user' : null, {

    // Override default fetcher 
    fetcher: async () => {

      // Attempt to get current user 
      const { response, data } = await useGetRequest(`/api/user`)

      // Check for failure
      if (!response.ok) {

        // If anything but verification, throw error
        if (response.status != 409) throw new Error('Could not authenticate')

        // Show verify if we need to 
        console.log('Redirecting to verification...')
        router.push('/verify-email')

      } else {

        // Check we have estates
        if (!data.has_estates) {
          console.log('Redirecting to onboarding...')
          router.push('/dashboard/onboarding')
        }

        // Return user 
        return data

      }
    }
  })

  /**
   * Get CSRF token 
   */
  const csrf = () => useGetRequest('/sanctum/csrf-cookie')

  /**
   * Register user 
   */
  const register = async ({ setErrors, estate, token, ...props }) => {

    // Get CSRF token 
    await csrf()

    // Clear errors 
    setErrors([])

    // Make register request 
    const { response, data } = await usePostRequest(`/register`, props)

    // Check for error
    if (!response.ok) {
      setErrors(data.errors ? Object.values(data.errors).flat() : 'Something went wrong')
      return false
    }

    // Check for token 
    if (token) {

      // Start processing to stop auth
      setProcessing(true)

      // Redirect to accept token link  
      window.location.href = process.env.NEXT_PUBLIC_API_URL + `/invites/${token}/accept`

    }

    // Check for estate
    if (estate) {

      // Start processing to stop auth
      setProcessing(true)

      // Submit estate 
      const { response: estateResponse, data: estateData } = await usePostRequest(`/api/estates`, estate)

      // Check for error
      if (!estateResponse.ok) {
        setErrors(estateData.errors ? Object.values(estateData.errors).flat() : 'Something went wrong')
        return false
      }

      // Start processing to stop auth
      setProcessing(false)

    }

    // Revalidate user
    revalidate()

    // Return user 
    return data

  }

  /**
   * Login user 
   */
  const login = async ({ setErrors, setStatus, token, ...props }) => {

    // Get CSRF token 
    await csrf()

    // Clear errors 
    setErrors([])

    // Clear status 
    setStatus(null)

    // Make register request 
    const { response, data } = await usePostRequest(`/login`, props)

    // Check for error
    if (!response.ok) {
      setErrors(data.errors ? Object.values(data.errors).flat() : 'Something went wrong')
      return false
    }

    // Check for token 
    if (token) {

      // Start processing to stop auth
      setProcessing(true)

      // Redirect to accept token link  
      window.location.href = process.env.NEXT_PUBLIC_API_URL + `/invites/${token}/accept`

    }

    // Revalidate user
    revalidate()

    // Return user 
    return data

  }

  /**
   * Forgot password
   */
  const forgotPassword = async ({ setErrors, setStatus, email }) => {

    // Get CSRF token 
    await csrf()

    // Clear errors 
    setErrors([])

    // Clear status 
    setStatus(null)

    // Make register request 
    const { response, data } = await usePostRequest(`/forgot-password`, { email })

    // Check for error
    if (!response.ok) {
      setErrors(data.errors ? Object.values(data.errors).flat() : 'Something went wrong')
      return false
    }

    // Set page status 
    setStatus(data.status)

  }

  /**
   * Reset password
   */
  const resetPassword = async ({ setErrors, setStatus, ...props }) => {

    // Get CSRF token 
    await csrf()

    // Clear errors 
    setErrors([])

    // Clear status 
    setStatus(null)

    // Make register request 
    const { response, data } = await usePostRequest(`/reset-password`, { token: router.query.token, ...props })

    // Check for error
    if (!response.ok) {
      setErrors(data.errors ? Object.values(data.errors).flat() : 'Something went wrong')
      return false
    }

    // Redirect to login 
    router.push('/login?reset=' + btoa(data.status))

  }

  /**
   * Resend email verification 
   */
  const resendEmailVerification = async ({ setStatus }) => {

    // Make register request 
    const { response, data } = await usePostRequest(`/email/verification-notification`)

    // Set page status 
    setStatus(data.status)

  }

  /**
   * Logout
   */
  const logout = async () => {

    // Action logout
    if (!error) {
      const { response, data } = await usePostRequest(`/logout`, null, true)
      await revalidate()
    }

    // Redirect 
    window.location.pathname = '/login'

  }

  /**
   * On user change, run middleware 
   */
  useEffect(() => {
    if (middleware == 'guest' && redirectIfAuthenticated && user) {

      // Check for token 
      if (token) {
        window.location.href = process.env.NEXT_PUBLIC_API_URL + `/invites/${token}/accept`
      }

      // Redirect 
      console.log('Logged in, redirect...')
      router.push(redirectIfAuthenticated)
      
    }
    if (middleware == 'auth' && error) {
      console.log('Not logged in, redirecting...')
      logout()
    }
  }, [user, error])

  return {
    user,
    guest,
    setGuest,
    register,
    login,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    logout,
    revalidate
  }
}
import { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"

export default function Modal({ visible, title, children, slim, onClose }) {
	return (
		<Transition appear show={visible} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				onClose={onClose}
			>
				<div className="flex items-center justify-center min-h-screen px-4 text-center">
					{/* Overlay */}
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-900 opacity-80" />
					</Transition.Child>

					{/* Content */}
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div
							className={`z-10 inline-block w-full my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-lg ${
								slim ? "max-w-md" : "max-w-3xl"
							}`}
						>
							{/* Header */}
							<div className="flex justify-between items-center divide-x border-b border-gray-200 divide-gray-200">
								<div className="px-6 py-3">
									{title && <h3 className="text-lg font-medium">{title}</h3>}
								</div>
								<div className="px-3 py-3">
									<button
										onClick={onClose}
										type="button"
										className="bg-white text-gray-800 p-1 rounded-sm hover:bg-gray-50"
									>
										<XIcon className="w-6 h-6" />
									</button>
								</div>
							</div>

							{/* Contents */}
							<div className="p-6">{children}</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	)
}
